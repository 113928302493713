import React from 'react'
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout"
import Seo from '../components/seo'

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const ValuationLanding = loadable(() => import("../components/ValuationLanding/ValuationLanding"));


const ValuationLandingTemplate = ({ data }) => {
    const PageData = data?.strapiPage

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule
                subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                title={PageData.title}
            />

            <ValuationLanding valuationDetails={PageData} />
        </Layout>
    )
}


export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo
            title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title}
            description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}
        />
    )
}

export default ValuationLandingTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_MODULE {
              ...ValuationModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }
}`